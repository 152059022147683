import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { VALID_IMAGE } from '@/utils/config'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function addProduct() {
  const defaultName = ref('')
  const slug = ref('')
  const defaultDescription = ref('')
  const imgID = ref(null)
  const imgFile = ref(null)
  const imgSrc = ref('https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png')
  const arrayProgramCategory = ref([
    {
      name: '',
      languageCode: '',
      slug: '',
      description: '',
    },
  ])
  const countProgramCategory = ref(1)
  const idProgramCategory = ref(null)
  const numberDefault = ref(null)
  const loading = ref(false)
  const toast = useToast()

  // Fetch post
  if (router.currentRoute.params.id) {
    loading.value = true
    idProgramCategory.value = router.currentRoute.params.id
    store
      .dispatch('programCategory/fetchProgramCategory', { id: idProgramCategory.value })
      .then(response => {
        const res = response.body
        slug.value = res.slug
        if (res.icon) {
          imgSrc.value = VALID_IMAGE + res.icon.key
          imgID.value = res.icon.id
        }

        countProgramCategory.value = res.translations.length
        arrayProgramCategory.value = res.translations.map(x => ({
          name: x.name,
          languageCode: store.state.app.lanCodeOptions.find(e => e.value === x.languageCode),
          slug: x.slug,
          description: x.description,
          isDefaultLanguage: x.isDefaultLanguage,
        })).reverse()
        numberDefault.value = arrayProgramCategory.value.findIndex(x => x.isDefaultLanguage)
        if (numberDefault.value === -1) {
          numberDefault.value = null
        }
        defaultName.value = res.name
        defaultDescription.value = res.description
        loading.value = false
      }).catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: e.response.data.error,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        loading.value = false
      })
  }

  return {
    arrayProgramCategory,
    countProgramCategory,
    defaultName,
    defaultDescription,
    slug,
    imgID,
    imgFile,
    imgSrc,
    loading,
    numberDefault,
  }
}
